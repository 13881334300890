import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import ConfigureStore from "./redux/configure-store";
import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";
import * as config from "./config";
import { loadableReady } from "@loadable/component";

if (config.default.gtmId !== "" && config.default.gtmId !== "GTM-XXXXXXX") {
  const tagManagerArgs = {
    gtmId: config.default.gtmId,
  };

  TagManager.initialize(tagManagerArgs);
}

const rootElement = document.getElementById("root");
const store = ConfigureStore();

if (rootElement.hasChildNodes()) {
  const store = ConfigureStore();
  loadableReady(() => {
    hydrate(
      <Provider store={store}>
        <App />
      </Provider>,
      rootElement
    );
  });
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
